import classNames from "classnames"
import React from "react"

import CircledIconButton from "../../elements/V2/Buttons/CircledIconButton"

import GatsbyStoryblokImage from "@components/elements/V2/GatsbyStoryblokImage"
import Typography from "@components/elements/V2/Typography"
import { Color } from "@constants/V2/color"
import { getAccentColor, getBackgroundColorClass } from "@utils/V2/color"

type Props = {
  title: string
  description?: string
  image?: string
  imageAlt?: string
  backgroundColor?: Color
  imageAsBackground?: boolean
  showDescriptionOnHover?: boolean
  size: "col-4" | "col-6" | "col-8" | "col-12"
  ctaLink?: string
}

const BentoCard = ({
  title,
  description,
  image,
  backgroundColor = Color.Blue,
  imageAsBackground = false,
  showDescriptionOnHover = true,
  size,
  imageAlt,
  ctaLink,
  ...props
}: Props) => {
  return (
    <div
      className={classNames(
        "overflow-none group relative flex h-[389px] items-center justify-between rounded sm:items-start",
        !imageAsBackground && getBackgroundColorClass(backgroundColor),
        size === "col-4"
          ? "col-span-full flex-col sm:col-span-2 lg:col-span-4"
          : size === "col-6"
            ? "col-span-full flex-col sm:col-span-2 md:col-span-3 lg:col-span-6"
            : size === "col-8"
              ? "col-span-full flex-col sm:col-span-2 sm:items-start md:col-span-4 md:flex-row lg:col-span-8"
              : "col-span-full flex-col sm:col-span-4 sm:flex-row sm:items-start md:col-span-6 lg:col-span-12"
      )}
      {...props}
    >
      {image && imageAsBackground && (
        <div className="absolute left-0 top-0 h-[389px] w-full">
          <GatsbyStoryblokImage
            image={image}
            alt={imageAlt ? imageAlt : ""}
            loadType="eager"
            quality={80}
            aspectRatio="fullWidth"
            rounded
            className="h-full w-auto"
          />
        </div>
      )}
      {!!ctaLink && (
        <div
          className={classNames(
            "absolute bottom-32 left-32 z-10 -translate-y-10 transition duration-300 ease-in-out sm:translate-y-0"
          )}
        >
          <CircledIconButton
            color={backgroundColor === Color.Blue ? Color.White : Color.Blue}
            linkUrl={ctaLink}
            style="solid"
            enterOnGroupHover
          />
        </div>
      )}
      <div
        className={classNames(
          "relative flex flex-col gap-10 px-40 pt-[50px]",
          description &&
            "-translate-y-10 transition duration-300 ease-in-out sm:translate-y-0",
          showDescriptionOnHover
            ? "-translate-y-10 md:group-hover:-translate-y-10"
            : "md:-translate-y-10",
          (size === "col-8" || size === "col-12") && "md:max-w-[45%]"
        )}
      >
        <Typography
          text={title}
          size="lead-md"
          font="grotesk"
          weight="medium"
          color={
            imageAsBackground ? Color.White : getAccentColor(backgroundColor)
          }
          className="max-w-[309px]"
        />
        {description && (
          <Typography
            text={description}
            size="body-sm"
            font="grotesk"
            weight="book"
            color={
              imageAsBackground ? Color.White : getAccentColor(backgroundColor)
            }
            className={classNames("max-w-[309px]", {
              "md:hidden md:group-hover:block": showDescriptionOnHover,
            })}
          />
        )}
      </div>

      {image && !imageAsBackground && (
        <div
          className={classNames(
            "max-h-full max-w-full overflow-hidden",
            size === "col-4" || size === "col-6"
              ? "absolute bottom-0 left-0 right-0 w-full lg:max-h-[80%] min-[2000px]:max-h-[90%]"
              : size === "col-12"
                ? "sm:absolute sm:bottom-0 sm:right-0 sm:w-[50%] md:w-[55%] lg:w-[50%]"
                : "md:absolute md:bottom-0 md:right-0 md:w-[55%] md:self-end"
          )}
        >
          <GatsbyStoryblokImage
            image={image}
            alt={imageAlt ? imageAlt : ""}
            className="pointer-events-none rounded-b"
            aspectRatio="4:3"
            imageObjectFit="cover"
          />
        </div>
      )}
    </div>
  )
}

export default BentoCard
