import { storyblokEditable } from "gatsby-source-storyblok"
import React from "react"

import {
  getUrlFromStoryblokLink,
  linkIsNotNull,
} from "../../../../utils/storyblok"

import BentoCard from "@components/V2/Cards/BentoCard"

const StoryblokBentoCard = ({
  blok,
}: Storyblok.BlokProps<Storyblok.BentoCard>) => {
  return (
    <BentoCard
      image={blok?.image?.filename}
      title={blok.title}
      description={blok.description}
      backgroundColor={blok.backgroundColor}
      imageAsBackground={blok.imageAsBackground}
      size={blok.size}
      imageAlt={blok?.image?.alt}
      showDescriptionOnHover={blok.showDescriptionOnHover}
      ctaLink={
        blok.ctaLink && linkIsNotNull(blok.ctaLink)
          ? getUrlFromStoryblokLink(blok.ctaLink)
          : undefined
      }
      // @ts-ignore to have more strict typing compared to Storybloks generic object typing
      {...storyblokEditable(blok)}
    />
  )
}

export default StoryblokBentoCard
